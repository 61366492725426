import { RunEnvironment } from "../utils/project-base/consts/run-environment";

/** BACKEND ENVIRONMENT VARIABLE */
export const isDev = process.env.NODE_ENV == "development";
export const domain = process.env.WEB_DOMAIN;
export const apiUrl = process.env.BACKEND_API_GATEWAY ?? "";
export const apiSecret = process.env.WEB_API_SECRET ?? "";
export const isTest = process.env.CYPRESS_TEST == "true";

/** FRONTEND ENVIRONMENT VARIABLE */
export const runEnvirinment = (process.env.NEXT_PUBLIC_RUN_ENV ?? "dev") as RunEnvironment;
export const stripePublicKey = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;
export const googleAnalyticsId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
export const metaPixelId = process.env.NEXT_PUBLIC_META_PIXEL_ID;
export const webStoreUrl = process.env.NEXT_PUBLIC_WEB_STORE_URL;
